package models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Stats(
    @SerialName("domain_count")
    val domainCount: Long,
    @SerialName("status_count")
    val statusCount: Long,
    @SerialName("user_count")
    val userCount: Long
)
