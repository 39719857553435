package utils

import kotlin.math.floor
import kotlin.math.pow

object NumberUtils {
    internal fun addSeparators(number: Number?): String? {
        if (number == null) return null
        return addSeparators(number.toLong(), 3, '.')
    }

    internal fun toReadableFormat(number: Number?): String? {
        if (number == null) return null
        return formatNumber(number.toDouble(), 0, 1000.toDouble())
    }

    private fun addSeparators(number: Long, groupSize: Int, separator: Char): String {
        val groupValue = 10.0.pow(groupSize).toInt()
        val floor = number.floorDiv(groupValue)
        val remain = number.rem(groupValue)
        return if (floor == 0L) {
            number.toString()
        } else {
            val textRemain = remain.toString().padStart(groupSize, '0')
            addSeparators(floor, groupSize, separator) + "$separator$textRemain"
        }
    }

    private const val units = " KMGTP"
    private fun formatNumber(number: Double, i: Int, base: Double): String {
        val unit = units[i]
        return if (number < base) {
            "$number $unit".trim()
        } else {
            var temp = (number * 10.0) / base
            temp = floor(temp) / 10.0
            formatNumber(temp, i + 1, base)
        }
    }
}
