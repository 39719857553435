package models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Accounts(
    @SerialName("max_featured_tags")
    val maxFeaturedTags: Int
)
