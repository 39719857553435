package models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Field(
    val name: String,
    val value: String,
    @SerialName("verified_at")
    val verifiedAt: String?
)
