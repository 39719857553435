import androidx.compose.runtime.*
import kotlinx.browser.document
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import models.InstanceData
import org.jetbrains.compose.web.attributes.ARel
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.ref
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.renderComposable
import org.w3c.dom.url.URL
import styles.AppStylesheet
import utils.NumberUtils

fun main() {
    renderComposable(rootElementId = "root") {
        var host: String? = null
        document.location?.let { location ->
            val urlParam = URL(location.href).searchParams.get("url")
            urlParam?.let { params ->
                if (params.isNotEmpty()) {
                    host = URL(params).origin
                }
            }
        }
        host = host ?: "https://paquita.masto.host"

        val coroutineScope = rememberCoroutineScope()
        var data by mutableStateOf<InstanceData?>(null)

        SideEffect {
            coroutineScope.launch {
                withContext(Dispatchers.Default) {
                    data = Api.getInstanceData(host!!)
                }
            }
        }

        Style(AppStylesheet)
        MainContentLayout {
            Img(
                src = data?.thumbnail ?: "banner.jpg",
                alt = "banner",
                attrs = { classes(AppStylesheet.banner) }
            )
            H1(
                attrs = { classes(AppStylesheet.title) }
            ) {
                A(
                    href = data?.uri?.let { "https://$it" },
                    attrs = {
                        target(ATarget.Blank)
                        ref(ARel.External)
                    }
                ) {
                    Text(data?.uri ?: "...")
                }
            }
            CardContainer {
                Card {
                    Div(
                        attrs = { classes(AppStylesheet.cardDataTitle) }
                    ) {
                        Text("Users:")
                    }
                    Div(
                        attrs = { classes(AppStylesheet.cardData) }
                    ) {
                        Text(NumberUtils.addSeparators(data?.stats?.userCount) ?: "...")
                    }
                }
                Card {
                    Div(
                        attrs = { classes(AppStylesheet.cardDataTitle) }
                    ) {
                        Text("Toots:")
                    }
                    Div(
                        attrs = { classes(AppStylesheet.cardData) }
                    ) {
                        Text(NumberUtils.addSeparators(data?.stats?.statusCount) ?: "...")
                    }
                }
            }
        }
    }
}
