package models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class InstanceData(
    @SerialName("approval_required")
    val approvalRequired: Boolean,
    val configuration: Configuration,
    @SerialName("contact_account")
    val contactAccount: ContactAccount,
    val description: String,
    val email: String,
    @SerialName("invites_enabled")
    val invitesEnabled: Boolean,
    val languages: List<String>,
    val registrations: Boolean,
    val rules: List<Rule>,
    @SerialName("short_description")
    val shortDescription: String,
    val stats: Stats,
    val thumbnail: String,
    val title: String,
    val uri: String,
    val urls: Urls,
    val version: String
)
