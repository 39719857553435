package models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ContactAccount(
    val acct: String,
    val avatar: String,
    @SerialName("avatar_static")
    val avatarStatic: String,
    val bot: Boolean,
    @SerialName("created_at")
    val createdAt: String,
    val discoverable: Boolean,
    @SerialName("display_name")
    val displayName: String,
    val emojis: List<Emoji>,
    val fields: List<Field>,
    @SerialName("followers_count")
    val followersCount: Int,
    @SerialName("following_count")
    val followingCount: Int,
    val group: Boolean,
    val header: String,
    @SerialName("header_static")
    val headerStatic: String,
    val id: String,
    @SerialName("last_status_at")
    val lastStatusAt: String,
    val locked: Boolean,
    val noindex: Boolean? = null,
    val note: String,
    @SerialName("statuses_count")
    val statusesCount: Int,
    val url: String,
    val username: String
)
