package models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Configuration(
    val accounts: Accounts? = null,
    @SerialName("media_attachments")
    val mediaAttachments: MediaAttachments,
    val polls: Polls,
    val statuses: Statuses
)
